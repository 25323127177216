import { useContext } from "react";
import TelehealthImage from "../components/upload/telehealth-image"
import SendAnnotationMessage from "./send-annotation-message"
import { MeetingInfoContext } from "../context/meeting-info-context";


const LiveAnnotationPanel: React.FC = () => {

    const { userId, annotationUrl, showAnnotation } = useContext(MeetingInfoContext);

    return (
        <SendAnnotationMessage userId={userId} >
            {showAnnotation && annotationUrl && <TelehealthImage currentImage={annotationUrl} />}
        </SendAnnotationMessage>
    )
}

export default LiveAnnotationPanel;