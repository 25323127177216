import React, { useContext, useEffect, useRef } from "react";

import { Stack } from "@mui/material";

import { useAudioVideo, useContentShareState, useLocalVideo, useRemoteVideoTileState, useRosterState } from "amazon-chime-sdk-component-library-react";

import VideoLabel from "./video-label";
import StyledIconShowSelfButton from "./styled-icon-show-self-button";
import { MeetingInfoContext } from "../../context/meeting-info-context";
import { MeetingJoinType } from "../../types";

interface SelectedAttendeeVideoProps {
    userName: string;
    type: MeetingJoinType;
    userId: string;
    showName?: boolean;
}

const SelectedAttendeeVideo: React.FC<SelectedAttendeeVideoProps> = ({
    userName,
    type,
    userId,
    showName = true,
}) => {

    const subType = `${type}`.slice(0, 3);
    const selectedUserName = `${userName}#${subType}`
    const selectedUser = `${userId}#${selectedUserName}`;

    const [attendeeId, setAttendeeId] = React.useState<number>(-1);

    const { isVideoEnabled } = useLocalVideo();
    const { attendeeIdToTileId } = useRemoteVideoTileState();

    const { roster } = useRosterState();
    const { isLocalUserSharing } = useContentShareState();
    const { showSelf } = useContext(MeetingInfoContext);

    const show = showSelf && !isLocalUserSharing;

    let toShowName = userName;

    if (type !== MeetingJoinType.Metro && type !== MeetingJoinType.Remote) {
        toShowName = `${userName} (${type})`;
    }

    useEffect(() => {
        for (const attendee in roster) {

            const currentName = roster[attendee]?.externalUserId;

            if (!currentName) {
                continue;
            }

            if (currentName === selectedUser) {
                setAttendeeId(attendeeIdToTileId[attendee]);
                return;
            }
        }

        setAttendeeId(-1);

    }, [roster, attendeeIdToTileId, selectedUser]);

    const audioVideo = useAudioVideo();
    const videoEl = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (!audioVideo || attendeeId < 0 || !videoEl.current) {
            return;
        }

        audioVideo.bindVideoElement(attendeeId, videoEl.current);

        return () => {
            const tile = audioVideo.getVideoTile(attendeeId);
            if (tile) {
                audioVideo.unbindVideoElement(attendeeId);
            }
        };
    }, [audioVideo, attendeeId]);

    return (
        <div
            style={{
                position: "relative",
                width: "fit-content",
                height: "fit-content",
            }}>
            {attendeeId > -1 ?
                <>
                    < video
                        ref={videoEl}
                        style={{
                            display: show ? "flex" : "none",
                            position: "absolute",
                            bottom: "1rem",
                            left: "1rem",
                            width: "20vw",
                            maxHeight: "30vh",
                            height: "auto",
                            zIndex: 100,
                        }}
                    />
                    {showName && show &&
                        <Stack width={"20vw"} >
                            <VideoLabel
                                name={toShowName}
                                distanceFromSide="1.25rem"
                                right={false}
                                zIndex={200} />
                        </Stack>}
                    {!isVideoEnabled && <div style={{
                        position: "absolute",
                        bottom: show ? "10rem" : "1rem",
                        right: show ? "4rem" : "-1rem",
                        zIndex: 100,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <StyledIconShowSelfButton right={false} zIndex={200} />
                    </div>
                    }
                </>
                : null}
        </div >
    );
}

export default SelectedAttendeeVideo;