import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Paper, Stack, Typography } from "@mui/material";

import LockResetIcon from '@mui/icons-material/LockReset';

import { AuthContext } from "../context/auth-context";

import LoginForm from "../components/login/login-form";
import NewPasswordChallengeForm from "../components/login/new-password-challenge-form";

import ClearCallLogo from "../components/clear-call-logo";
import TelehealthStyledButton from "../components/telehealth-styled-button";
import SetDetails from "../components/login/set-details";

const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © Clear Call "}
      {new Date().getFullYear()}
    </Typography>
  );
};

const Login: React.FC = () => {

  const [newPasswordRequired, setNewPasswordRequired] = React.useState<boolean>(false);
  const [firstLogin, setFirstLogin] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [submissionError, setSubmissionError] = React.useState<string>("");

  const { authenticateUser, completeNewPasswordChallenge } = React.useContext(AuthContext);

  const navigate = useNavigate();

  const redirectOnAuthenticateUser = async (email: string, password: string) => {
    authenticateUser(email, password, {
      onSuccess: () => {
        setSubmissionError('');
        if (!firstLogin) {
          navigate("/dashboard");
        }
      },
      onFailure: () => {
        setSubmissionError('Invalid email or password');
      },
      newPasswordRequired: () => {
        setEmail(email);
        setNewPasswordRequired(true);
        setSubmissionError('');
      },
    });
  };

  const redirectOnNewPasswordChallenge = (password: string) => {

    completeNewPasswordChallenge(
      password, {
      onSuccess: () => {
        setSubmissionError('');
        redirectOnAuthenticateUser(email, password);
        setNewPasswordRequired(false);
        setFirstLogin(true);
      },
      onFailure: (err) => {
        console.log(err, "error");
      },
    });
  };

  const onSetDetailsSuccess = () => {
    navigate("/dashboard");
  }

  const onSetDetailsError = (error: any) => {
    console.log(error);
    navigate("/dashboard");
  }

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        backgroundColor: "#EBEBF4",
        backgroundImage: "radial-gradient(#323AD2 0.75px, #EBEBF4 0.75px)",
        backgroundSize: "15px 15px",
      }}
    >
      <Paper
        elevation={24}
        sx={{
          maxWidth: 400,
          px: 8,
          py: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 2,
          backgroundSize: "100% 100%",
          backgroundPosition: "0px 0px",
          backgroundImage:
            "linear-gradient(0deg, #E0E0E0FF 0%, #FFFFFFFF 100%)",
        }}
      >
        <Stack spacing={3} alignItems="center">
          <ClearCallLogo size={40} />
          <Typography variant="h3" sx={{ mb: 3 }}>
            Log in
          </Typography>
          {newPasswordRequired &&
            <NewPasswordChallengeForm
              completeNewPasswordChallenge={redirectOnNewPasswordChallenge}
              email={email}
            />}
          {firstLogin &&
            <SetDetails
              updateText="Continue"
              onSuccess={onSetDetailsSuccess}
              onError={onSetDetailsError}
              requireUpdate={false} />}
          {!newPasswordRequired && !firstLogin &&
            <>
              <LoginForm authenticateUser={redirectOnAuthenticateUser} submissionError={submissionError} />
              <TelehealthStyledButton
                navigationPath='/forgotpassword'
                label="Forgot Password?"
                showText={true}
                onClick={() => { }}
                icon={<LockResetIcon />} />
            </>}
          <Copyright sx={{ mt: 5 }} />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default Login;
