import { useEffect, useState } from "react"

import { Stack } from "@mui/material"

import { Calendar, dateFnsLocalizer, SlotInfo, View } from "react-big-calendar"
import "../../styles/event-calendar.css"

import format from "date-fns/format"
import parse from "date-fns/parse"
import startOfWeek from "date-fns/startOfWeek"
import getDay from "date-fns/getDay"
import { enAU } from "date-fns/locale"

import AddMeetingModal from "./add-meeting-modal"

import { Meeting, Organisation, User, OrganisationType, MeetingStatus } from "../../types"

import MeetingInfoCompact from "./meeting-info-compact"
import MeetingInfoAgenda from "./meeting-info-agenda";
import MeetingInfoModal from "./meeting-info-modal";

import React from "react";
import { grey } from "@mui/material/colors";

const locales = {
  "en-AU": enAU
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

interface EventCalendarProps {
  meetingList: Meeting[]
  organisationList: Organisation[]
  addIsOpen: boolean
  closeAddModal: () => void
  userId: string,
  currentUser?: User
  currentOrganisation?: Organisation | null
  addMeeting: (meeting: Meeting) => void
  deleteMeeting: (meeting: Meeting) => void
  updateMeeting: (meeting: Meeting) => void
}

const EventCalendar: React.FC<EventCalendarProps> = ({
  meetingList,
  organisationList,
  addIsOpen,
  closeAddModal,
  userId,
  currentUser,
  currentOrganisation,
  addMeeting,
  deleteMeeting,
  updateMeeting,
}: EventCalendarProps) => {

  const lastView = localStorage.getItem("lastView") as View | null

  const [currentEventIndex, setCurrentEventIndex] = useState<number>(0)

  const [currentSlot, setCurrentSlot] = useState<SlotInfo | null>(null)

  const [useStartDateInForm, setUseStartDateInForm] = React.useState<boolean>(true)

  const [eventInfoModal, setEventInfoModal] = useState(false)

  const [view, setView] = useState<View>(lastView || "week" as View)

  const organisationDictionary = organisationList.reduce((acc, org) => {
    acc[org.organisationId] = org
    return acc
  }, {} as { [key: string]: Organisation })

  useEffect(() => {
    if (!currentOrganisation) {
      return;
    }

    if (lastView != null) {
      return;
    }

    if (currentOrganisation.type == OrganisationType.Remote) {
      onView("agenda" as View)
    }

  }, [currentOrganisation])

  useEffect(() => {
    if (addIsOpen) {
      addButtonClick()
      setUseStartDateInForm(false)
    }
  }, [addIsOpen])

  const handleSelectSlot = (event: SlotInfo) => {

    if (!currentOrganisation) {
      return;
    }

    if (currentOrganisation.type != OrganisationType.Metro) {
      return;
    }

    setCurrentSlot(event)
  }

  const handleSelectEvent = (event: Meeting) => {

    if (view == "agenda") {
      return
    }

    const index = meetingList.findIndex((meeting) => meeting.meetingId === event.meetingId);

    setCurrentEventIndex(index)
    setEventInfoModal(true)
  }

  const handleClose = () => {
    setCurrentSlot(null)
    closeAddModal()
    setUseStartDateInForm(true)
  }

  const onAddEvent = (eventFormData: Meeting) => {
    addMeeting(eventFormData)
    handleClose()
  }

  const onDeleteEvent = (meeting: Meeting) => {
    deleteMeeting(meeting)
    setEventInfoModal(false)
  }

  const onView = (view: View) => {
    setView(view)
    localStorage.setItem("lastView", view)
  }

  const addButtonClick = () => {
    const slot: SlotInfo = {
      start: new Date(),
      end: new Date(),
      slots: [],
      action: "select"
    }

    handleSelectSlot(slot)
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      height={"100%"}>
      {currentSlot && <AddMeetingModal
        useStartDateInForm={useStartDateInForm}
        open={currentSlot != null}
        handleClose={handleClose}
        startTime={currentSlot.start}
        patientId={""}
        userId={userId}
        organisationIds={[currentUser?.organisationId || ""]}
        addMeeting={onAddEvent}
      />}
      <MeetingInfoModal
        open={eventInfoModal && meetingList[currentEventIndex] !== undefined}
        handleClose={() => setEventInfoModal(false)}
        updateMeeting={updateMeeting}
        deleteMeeting={onDeleteEvent}
        meeting={meetingList[currentEventIndex]}
        currentUser={currentUser}
        userOrganisation={currentOrganisation}
      />
      <Calendar
        localizer={localizer}
        events={meetingList}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        onView={onView}
        view={view}
        eventPropGetter={(event, start, end, isSelected) => {

          let organisationId = ""
          for (const orgId of event.organisationIds) {
            if (orgId != currentUser?.organisationId) {
              organisationId = orgId
              break
            }
          }

          let organisationColor = organisationDictionary[organisationId]?.color || "white";

          if (event.meetingType == MeetingStatus.Cancelled) {
            organisationColor = grey[400];
          }

          return {
            style: {
              backgroundColor: view == "agenda" ? "white" : organisationColor,
              borderColor: "black",
              color: "black",
            },
          }
        }}
        selectable
        startAccessor="startTime"
        components={{
          event: view == "agenda" ? MeetingInfoAgenda : MeetingInfoCompact,
        }}
        endAccessor="endTime"
        defaultView="week"
        views={["month", "week", "agenda"]}
        onShowMore={(events, date) => {
          setView("week")
        }}
        style={{
          width: "98vw",
          height: "90vh",
          overflow: "auto",
        }}
      />
    </Stack>
  )
}

export default EventCalendar
