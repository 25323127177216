import React, { useContext, useEffect, useState } from "react";

import { BufferGeometry, Euler, MeshStandardMaterial, NormalBufferAttributes, Vector3 } from "three";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import { useLoader } from "@react-three/fiber";
import { Html } from "@react-three/drei";

import ExpirationCard from "./expiration-card";
import { ThreeContext } from "../../context/three-context";

interface ModelFromUrlProps {
    url: string;
    position?: Vector3;
    rotation?: Euler;
    expired: boolean;
    onClick: (event: any) => void;
}

const ModelFromUrl: React.FC<ModelFromUrlProps> = ({
    url,
    position,
    rotation,
    expired,
    onClick
}: ModelFromUrlProps) => {

    const { setIsLoading } = useContext(ThreeContext);

    const [geometry, setGeometry] = useState<BufferGeometry | undefined>(undefined);

    useEffect(() => {
        if (expired) {
            setGeometry(new BufferGeometry());
            setIsLoading(false);
        } else {
            setIsLoading(true);
            const loader = new PLYLoader();
            loader.load(
                url,
                (loadedGeometry) => {
                    setGeometry(loadedGeometry);
                    setIsLoading(false);
                },
                undefined,
                (error) => {
                    console.error('Error loading model:', error);
                    setIsLoading(false);
                }
            );
        }
    }, [url, expired]);

    const material = new MeshStandardMaterial({
        vertexColors: geometry?.attributes.color !== undefined,
        metalness: 0.5,
        roughness: 0.5,
    });

    const [currentTime, setCurrentTime] = React.useState<number>(0);

    const onPointerDown = (event: any) => {
        const currentTime = new Date().getTime();

        setCurrentTime(currentTime);
    }

    const onPointerUp = (event: any) => {

        const pointUpTime = new Date().getTime();

        const timeDiff = pointUpTime - currentTime;

        // don't call on click if a drag event
        if (timeDiff < 150) {
            onClick(event);
        }
    }

    return (
        <>
            <Html position={[0, 0, 0]}>
                {expired &&
                    <ExpirationCard message="Model has expired" />}
            </Html>
            <mesh
                position={position}
                rotation={rotation}
                geometry={geometry}
                scale={100}
                onPointerDown={onPointerDown}
                onPointerUp={onPointerUp}>
                <primitive object={material} attach="material" />
            </mesh>
        </>
    );
};

export default ModelFromUrl;