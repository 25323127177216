import React, { useContext } from "react";

import { Paper, Stack } from "@mui/material";

import { WindowSizeContext } from "../context/window-size-context";

import DeviceChimeControlBar from "../components/meeting/device-chime-control-bar";
import FilteredVideoTileGrid from "../components/meeting/filtered-video-tile-grid";
import { MeetingInfoContext } from "../context/meeting-info-context";
import CustomLocalView from "../components/meeting/custom-local-view";


const DeviceMeeting: React.FC = () => {

    const { isPortrait } = useContext(WindowSizeContext);
    const { showSelf } = useContext(MeetingInfoContext);

    const viewHeight = 75;
    const viewWidth = 75;

    return (
        <Stack
            sx={{
                minHeight: "100vh",
                minWidth: "100vw",
                backgroundColor: "#2E3033",
            }}
        >
            <Stack
                spacing={1}
                direction={isPortrait ? "column-reverse" : "row"}
                sx={{
                    alignItems: "left",
                    justifyContent: "centre",
                    padding: 1,
                }}>
                <DeviceChimeControlBar showText={true} />
                <Stack
                    spacing={2}
                    sx={{
                        width: "fit-content",
                        height: "fit-content",
                        padding: 0.5,
                    }}
                    aria-hidden={true}
                >
                    {showSelf ?
                        <CustomLocalView
                            showNamePlate={false}
                            showNotSharingMessage={true}
                            viewHeight={viewHeight}
                            viewWidth={viewWidth}
                        /> :
                        <FilteredVideoTileGrid
                            showNamePlate
                            viewHeight={viewHeight}
                            viewWidth={viewWidth}
                            excludeSelf={true} />}
                </Stack >
            </Stack>
        </Stack>
    );

};

export default DeviceMeeting;