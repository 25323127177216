import React, { createContext, PropsWithChildren, useState } from "react";

import { AuthenticationDetails, CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession, IAuthenticationCallback, NodeCallback } from "amazon-cognito-identity-js";
import { CognitoIdentityClient, GetCredentialsForIdentityCommand, GetIdCommand } from "@aws-sdk/client-cognito-identity";
import { assignIOTPermissions } from "../services/users-service";

const region = import.meta.env.VITE_AWS_REGION as string;
const identityPoolId = import.meta.env.VITE_IDENTITY_POOL_ID as string;

interface CustomCredentials {
  accessKeyId?: string;
  secretKey?: string;
  sessionToken?: string;
  identityId?: string;
}

export type AuthContextProps = {
  userId: string;
  isAuthenticated: boolean;
  tryGetStoredSession: () => Promise<string>;
  assumeIdentity: (checkIOTPermissions: boolean) => Promise<CustomCredentials | null>;
  authenticateUser: (email: string, password: string, callbacks: IAuthenticationCallback) => void;
  completeNewPasswordChallenge(newPassword: string, callbacks: IAuthenticationCallback): void;
  resetPassword: (email: string, callbacks: IAuthenticationCallback) => void;
  confirmForgotPassword: (email: string, verificationCode: string, newPassword: string, callbacks: { onSuccess: (success: string) => void; onFailure: (err: Error) => void; }) => void;
  updatePassword: (oldPassword: string, newPassword: string, callback: NodeCallback<Error, 'SUCCESS'>) => void;
  logout: () => void;
};

export const AuthContext = createContext<AuthContextProps>({
  userId: "",
  tryGetStoredSession: () => new Promise(() => ""),
  assumeIdentity: () => new Promise(() => null),
  isAuthenticated: false,
  authenticateUser: () => { },
  completeNewPasswordChallenge: () => { },
  resetPassword: () => { },
  updatePassword: () => { },
  confirmForgotPassword: () => { },
  logout: () => { },
});

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userSession, setUserSession] = useState<CognitoUserSession | null>(null);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [userId, setUserId] = useState<string>("");
  const [addedAttributes, setAddedAttributes] = useState<Boolean>(false);

  const envUserPoolId = import.meta.env.VITE_USER_POOL_ID as string;
  const envClientId = import.meta.env.VITE_CLIENT_ID as string;

  const userPool: CognitoUserPool = new CognitoUserPool({
    UserPoolId: envUserPoolId,
    ClientId: envClientId,
  });

  const tryGetStoredSession = async () => {

    const userSession = await tryGetUserSession();

    if (!userSession) {
      return "";
    }

    return userSession.getIdToken().getJwtToken();
  }

  const tryGetUserSession = async (): Promise<CognitoUserSession | null> => {
    if (userSession && userSession.isValid()) {
      return userSession;
    }

    const userName = localStorage.getItem("userName");
    const refreshToken = localStorage.getItem("refreshToken");
    const idToken = localStorage.getItem("idToken");
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");

    if (!userName || !refreshToken || !idToken || !accessToken || !userId) {
      return null;
    }

    const parsedRefreshToken = new CognitoRefreshToken({
      RefreshToken: refreshToken,
    });

    const parsedIdToken = new CognitoIdToken({
      IdToken: idToken,
    });

    const parsedAccessToken = new CognitoAccessToken({
      AccessToken: accessToken,
    });

    const parsedSession = new CognitoUserSession({
      IdToken: parsedIdToken,
      AccessToken: parsedAccessToken,
      RefreshToken: parsedRefreshToken,
    });

    const cognitoUser = new CognitoUser({
      Username: userName,
      Pool: userPool,
    });

    if (!parsedSession.isValid()) {
      return null;
    }

    if (!parsedSession.getAccessToken()) {
      return null;
    }

    if (!parsedSession.getIdToken()) {
      return null;
    }

    if (!parsedSession.getRefreshToken()) {
      return null;
    }

    return await new Promise<CognitoUserSession | null>((resolve, reject) => {
      cognitoUser.refreshSession(parsedSession.getRefreshToken(), (err, session) => {
        if (err) {
          console.log("error refreshing session", err);
          reject(null);
        }

        console.log("refreshed session checkIsAuthenticated");

        setUserSessionInLocalStorage(session, cognitoUser, userId);
        setIsAuthenticated(true);
        resolve(session);
      });
    });
  }


  const assumeIdentity = async (checkIOTPermissions: boolean): Promise<CustomCredentials | null> => {
    const userSession = await tryGetUserSession();

    if (!userSession) {
      console.log('No user session or token');
      return null;
    }

    // const accessToken = userSession?.getAccessToken().getJwtToken();
    const idToken = userSession?.getIdToken().getJwtToken();

    const cognitoClient = new CognitoIdentityClient({ region: region });
    const getIdCommand = new GetIdCommand({
      IdentityPoolId: identityPoolId,
      Logins: {
        [`cognito-idp.${region}.amazonaws.com/${userSession.getIdToken().payload.iss.split('/')[3]}`]: idToken,
      },
    });
    const identityResponse = await cognitoClient.send(getIdCommand);
    const identityId = identityResponse.IdentityId;

    // Get the credentials for the identity
    const getCredentialsCommand = new GetCredentialsForIdentityCommand({
      IdentityId: identityId,
      Logins: {
        [`cognito-idp.${region}.amazonaws.com/${userSession.getIdToken().payload.iss.split('/')[3]}`]: idToken,
      },
    });

    let credentialsResponse = null;

    try {
      credentialsResponse = await cognitoClient.send(getCredentialsCommand);
    } catch (error: any) {
      console.log("Error: " + error);
      return null;
    }

    if (!credentialsResponse.Credentials) {
      console.error('No credentials');
      return null;
    }

    const credentials: CustomCredentials = {
      accessKeyId: credentialsResponse.Credentials.AccessKeyId,
      secretKey: credentialsResponse.Credentials.SecretKey,
      sessionToken: credentialsResponse.Credentials.SessionToken,
      identityId: identityId,
    };

    if (checkIOTPermissions) {
      console.log('Checking IOT permissions');
      await grantWebSocketAccess(idToken, identityId || "");
    }

    return credentials;
  }

  const grantWebSocketAccess = async (token: string, identityId: string) => {

    if (addedAttributes) {
      return;
    }

    try {
      const response = await assignIOTPermissions(token, identityId);

      console.log(response);
    }
    catch (error: any) {
      console.log("Error: " + error);
    }

    setAddedAttributes(true);
  }

  const setUserSessionInLocalStorage = (session: CognitoUserSession, user: CognitoUser, userId: string) => {
    setUserSession(session);
    setCognitoUser(user);
    setUserId(userId);

    localStorage.setItem("userId", userId);
    localStorage.setItem("userName", user.getUsername());
    localStorage.setItem("refreshToken", session.getRefreshToken().getToken());
    localStorage.setItem("idToken", session.getIdToken().getJwtToken());
    localStorage.setItem("accessToken", session.getAccessToken().getJwtToken());
  }

  const authenticateUser = (
    email: string,
    password: string,
    callbacks: IAuthenticationCallback
  ): void => {

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        setUserSessionInLocalStorage(result, cognitoUser, result.getIdToken().payload["custom:userId"]);
        setIsAuthenticated(true);
        callbacks.onSuccess(result);
      },
      onFailure: (err) => {
        console.log(err, "error");
        callbacks.onFailure(err);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        setCognitoUser(cognitoUser);

        if (callbacks.newPasswordRequired) {
          callbacks.newPasswordRequired(userAttributes, requiredAttributes);
        }
      },
    });
  };

  const completeNewPasswordChallenge = (
    newPassword: string,
    callbacks: IAuthenticationCallback,
  ): void => {

    cognitoUser?.completeNewPasswordChallenge(
      newPassword,
      {}, {
      onSuccess: (result) => {
        setUserSessionInLocalStorage(result, cognitoUser, userId);
        setIsAuthenticated(true);
        callbacks.onSuccess(result);
      },
      onFailure: (err) => {
        if (err.code === "InvalidPasswordException") {
          throw Error(
            "Password must be at least 8 characters long and contain at least one number and one lowercase letter"
          );
        }
        console.log(err);
        callbacks.onFailure(err);
      },
    });
  }

  const resetPassword = (email: string, callbacks: IAuthenticationCallback) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        callbacks.onSuccess(result);
      },
      onFailure: (err) => {
        callbacks.onFailure(err);
      },
    });
  };

  const confirmForgotPassword = (email: string, verificationCode: string, newPassword: string, callbacks: {
    onSuccess: (success: string) => void;
    onFailure: (err: Error) => void;
  }) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: (success: string) => {
        console.log(success);
        callbacks.onSuccess(success);
      },
      onFailure: (err) => {
        console.log(err);
        callbacks.onFailure(err);
      },
    });
  };

  const updatePassword = (oldPassword: string, newPassword: string, callback: NodeCallback<Error, 'SUCCESS'>) => {

    if (!cognitoUser) {
      return;
    }

    cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
      if (err) {
        console.log(err);
        callback(err);
      }
      else {
        callback();
      }
    });
  };

  const logout = () => {

    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");

    if (cognitoUser) {
      cognitoUser.signOut();
    }

    setIsAuthenticated(false);
    setUserId("");
  };

  return (
    <AuthContext.Provider
      value={{
        userId,
        isAuthenticated,
        tryGetStoredSession,
        assumeIdentity,
        authenticateUser,
        completeNewPasswordChallenge,
        resetPassword,
        confirmForgotPassword,
        updatePassword,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
