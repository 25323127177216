import React, { PropsWithChildren } from "react";
import { Drawing } from "./send-annotation-message";
import DrawingLine from "./drawing-line";

interface AnnotationGridProps {
    points: { [id: string]: Drawing };
    onClick: (x: number, y: number) => void;
}

const AnnotationGridMessage: React.FC<PropsWithChildren<AnnotationGridProps>> = ({
    points,
    onClick,
    children,
}) => {

    const divClick = (e: React.MouseEvent<HTMLDivElement>) => {

        // get cursor position
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        onClick(x, y);
    }

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: '100%'
        }} onClick={divClick}>
            {children}
            {
                Object.keys(points).map((key) => (
                    <div
                        key={`drawingDiv${key}`}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 0,
                        }}
                    >
                        <DrawingLine userId={key} key={key} drawing={points[key]} />
                    </div>))
            }
        </div>
    );
};

export default AnnotationGridMessage;