import * as React from 'react';

import { styled } from '@mui/material/styles';
import { Toolbar, Drawer, Typography, Stack } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import DescriptionIcon from '@mui/icons-material/Description';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import NotesPanel from './notes-panel';
import MeetingControlBar from './meeting-control-bar';
import NormalMeeting from './normal-meeting';
import TelehealthStyledButton from '../telehealth-styled-button';

const drawerWidth = "15vw";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: drawerWidth,
    display: open ? 'flex' : 'none',
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100vw - ${drawerWidth})`,
        marginRight: `${drawerWidth}`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const SizeChangeStack = styled(Stack, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    alignItems: "center",
    height: "80vh",
    width: open ? undefined : "100%",
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100vw - ${drawerWidth})`,
        marginRight: `${drawerWidth}`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const NormalMeetingDrawer: React.FC = () => {

    const [isOpen, setIsOpen] = React.useState(false);

    const viewHeight = 92;
    const viewWidth = isOpen ? 85 : 100;

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    }

    return (
        <Stack
            height={"100vh"}
            width={`${viewWidth}vw`}
            sx={{
                display: 'flex',
                backgroundColor: '#000000',
            }}>
            <AppBar position="sticky"
                open={isOpen}
                sx={{
                    backgroundColor: "#F5F5F5",
                }}>
                <Toolbar disableGutters>
                    <MeetingControlBar>
                        <TelehealthStyledButton
                            showText={false}
                            icon={
                                <>
                                    {isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                    <DescriptionIcon />
                                </>}
                            label={"Notes"}
                            onClick={toggleDrawer} />
                    </MeetingControlBar>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: "fit-content",
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="right"
                open={isOpen}
            >
                <DrawerHeader>
                    <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                        justifyContent={"left"}
                        sx={{
                            width: drawerWidth,
                            padding: 1,
                        }}>
                        <DescriptionIcon color="primary" />
                        <Typography variant="h4" color="primary">Notes</Typography>
                    </Stack>
                </DrawerHeader>
                <NotesPanel />
            </Drawer>
            <SizeChangeStack open={isOpen} id="size-change-stack">
                <NormalMeeting
                    viewHeight={viewHeight}
                    viewWidth={viewWidth}
                    remote={false} />
            </SizeChangeStack>
            <Main open={isOpen}>
                <DrawerHeader />
            </Main>
        </Stack >
    );
}

export default NormalMeetingDrawer;