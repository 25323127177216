
interface VideoLabelProps {
    name: string;
    right: boolean;
    zIndex: number;
    distanceFromSide?: string;
}

const VideoLabel: React.FC<VideoLabelProps> = ({
    name,
    right,
    zIndex,
    distanceFromSide = "1rem",
}) => {

    return (
        <div style={{
            position: "absolute",
            backdropFilter: `blur(20px)`,
            backgroundColor: `rgba(46, 47, 52, 0.7)`,
            borderRadius: `0.25rem`,
            color: `white`,
            bottom: distanceFromSide,
            left: right ? '' : distanceFromSide,
            right: right ? distanceFromSide : '',
            width: "fit-content",
            padding: "0.5rem",
            zIndex: zIndex,
            justifySelf: "start",
        }}>
            {name}
        </div>
    )
}

export default VideoLabel;