import { PropsWithChildren } from "react";

import { Card, Divider, SelectChangeEvent, Stack, Typography, useTheme } from "@mui/material";

import StraightenIcon from '@mui/icons-material/Straighten';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import TimelineIcon from '@mui/icons-material/Timeline';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CancelIcon from '@mui/icons-material/Cancel';

import ControlButton from "../control-button";
import OrganisationColorSelect from "../organisation/organisation-color-select";

interface ModelControlPanelProps {
    showAreaTool: boolean;
    onAreaToolToggle: () => void;
    onMeasureToolToggle: () => void;
    onCenterModel: () => void;
    onUndo: () => void;
    canReset: boolean;
    onReset: () => void;
    color: string;
    setColor: (color: string) => void;
}

const ModelControlPanel: React.FC<PropsWithChildren<ModelControlPanelProps>> = ({
    showAreaTool,
    onAreaToolToggle,
    onMeasureToolToggle,
    onCenterModel,
    onUndo,
    canReset,
    onReset,
    color,
    setColor,
    children,
}) => {
    const theme = useTheme();

    const handleColorChange = (event: SelectChangeEvent<string>) => {
        setColor(event.target.value as string);
    }

    return (
        <Stack
            direction={"column"}
            spacing={1}
            width={"fit-content"}
            alignItems={"center"}
            alignContent={"center"}>
            <ControlButton
                onClick={onAreaToolToggle}
                showText={false}
                active={showAreaTool}
                activeColor={theme.palette.primary.light}
                activeLabel="Turn off Area Tool"
                inactiveLabel="Turn on Area Tool"
                inactiveIcon={<TimelineIcon />}
                activeIcon={<TimelineIcon />}
                tooltipPlacement="right" />
            <ControlButton
                onClick={onMeasureToolToggle}
                showText={false}
                active={!showAreaTool}
                activeColor={theme.palette.primary.light}
                activeLabel="Turn off Measure Tool"
                inactiveLabel="Turn on Measure Tool"
                inactiveIcon={<StraightenIcon />}
                activeIcon={<StraightenIcon />}
                tooltipPlacement="right" />
            <Divider />
            <ControlButton
                onClick={onCenterModel}
                showText={false}
                active={false}
                activeColor={theme.palette.primary.light}
                activeLabel="Center Model"
                inactiveLabel="Center Model"
                inactiveIcon={<CenterFocusStrongIcon />}
                tooltipPlacement="right" />
            <ControlButton
                onClick={onUndo}
                showText={false}
                disabled={!canReset}
                active={canReset}
                activeColor={theme.palette.primary.light}
                activeLabel={"Undo Last Point"}
                inactiveLabel={"Undo Last Point"}
                inactiveIcon={<RestartAltIcon />}
                activeIcon={<RestartAltIcon />}
                tooltipPlacement="right" />
            <ControlButton
                onClick={onReset}
                showText={false}
                disabled={!canReset}
                active={canReset}
                activeColor={theme.palette.primary.light}
                activeLabel={"Reset Points"}
                inactiveLabel={"Reset Points"}
                inactiveIcon={<CancelIcon />}
                activeIcon={<CancelIcon />}
                tooltipPlacement="right" />
            <Card sx={{
                backgroundColor: "lightgray",
            }}>
                <OrganisationColorSelect
                    compact={true}
                    selectedColor={color}
                    onSelectColor={handleColorChange} />
            </Card>
            {children}
        </Stack>
    );
};

export default ModelControlPanel;