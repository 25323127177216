import { QueryClient } from '@tanstack/react-query';

import { ResourceType, IotMessage, MessageEventType, User } from '../types';


const ignoreSelfTypes = [ResourceType.Meetings, ResourceType.Users, ResourceType.Organisation, ResourceType.Patients];

const handleUpdate = (iotMessage: IotMessage, queryClient: QueryClient) => {

  const cacheKey = iotMessage.resourceType;

  switch (iotMessage.eventType) {
    case MessageEventType.Created:
      console.log(`~~${cacheKey} Meeting`, iotMessage.eventType);
      queryClient.invalidateQueries({
        queryKey: [cacheKey],
        refetchType: "all",
      });
      break;
    case MessageEventType.Updated:
      console.log(`~~${cacheKey} Meeting'`, iotMessage.eventType);
      invalidateQueries(queryClient, cacheKey, iotMessage.resourceIds);
      break;
    case MessageEventType.Deleted:
      console.log(`~~${cacheKey} Meeting`, iotMessage.eventType);
      queryClient.invalidateQueries({
        queryKey: [cacheKey],
        refetchType: "all",
      });
      break;
    default:
      console.warn(`~~Not handled ${cacheKey} update`, iotMessage);
  }
}

const invalidateQueries = (queryClient: QueryClient, resourceType: ResourceType, queryKey: string[]) => {
  for (const key of queryKey) {
    queryClient.invalidateQueries({
      queryKey: [resourceType, key],
      refetchType: "all",
    });
  }
}

export const handleIotMessage = (topicName: string, payload: string, user: User, queryClient: QueryClient): void => {
  const iotMessage: IotMessage = JSON.parse(payload);

  if (!iotMessage.resourceType) {
    console.warn(`~~Invalid message ${topicName}`, iotMessage);
    return;
  }

  if (ignoreSelfTypes.includes(iotMessage.resourceType) && iotMessage?.sender === user.userId) {
    console.log(`~~Ignoring own ${iotMessage.resourceType} message`, iotMessage.eventType);
    return;
  }

  handleUpdate(iotMessage, queryClient);
}