import React, { useContext } from "react";

import DownloadIcon from '@mui/icons-material/Download';

import TelehealthDialogue from "../telehealth-dialogue";
import TelehealthImage from "./telehealth-image";
import DownloadBar from "../download-bar";
import UnseenItemButton from "./unseen-item-button";
import { urlExpired } from "./url-expired";

import ActivateLiveAnnotation from "./activate-live-annotation";

interface DownloadImageProps {
    downloadURLs: string[];
    showText?: boolean;
    liveAnnotation?: boolean;
}

const DownloadImageModal: React.FC<DownloadImageProps> = ({
    downloadURLs,
    showText = true,
    liveAnnotation = false
}) => {

    console.log("Download URLs", downloadURLs);

    const linkRef = React.useRef<HTMLAnchorElement>(null);

    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

    const [seenImages, setSeenImages] = React.useState<number[]>([]);

    const [modelOpen, setModelOpen] = React.useState(false);

    const unseenImages = downloadURLs.length - seenImages.length;

    const currentImage = (currentImageIndex > -1 && currentImageIndex < downloadURLs.length)
        ? downloadURLs[currentImageIndex] : ""

    const indexLabel = downloadURLs.length > 1 ? ` (${currentImageIndex + 1}/${downloadURLs.length})` : "";
    const title = "Download Preview" + indexLabel;

    const currentExpired = urlExpired(currentImage);

    const selectCurrentIndex = (index: number) => {
        if (!seenImages.includes(index)) {
            setSeenImages([...seenImages, index]);
        }

        setCurrentImageIndex(index);
    }

    const openModal = () => {
        setModelOpen(true);

        const firstUnseenIndex = getFirstUnseenIndex();

        setCurrentImageIndex(firstUnseenIndex);

        if (!seenImages.includes(firstUnseenIndex)) {
            setSeenImages([...seenImages, firstUnseenIndex]);
        }
    }

    const closeModal = () => {
        setModelOpen(false);
    }

    const acceptDownload = () => {

        if (linkRef.current) {
            linkRef.current.click();
        }

        closeModal();
    }

    const getFirstUnseenIndex = () => {
        let firstUnseenIndex = currentImageIndex;

        for (let i = 0; i < downloadURLs.length; i++) {
            if (!seenImages.includes(i)) {
                firstUnseenIndex = i;
                break;
            }
        }

        return firstUnseenIndex;
    }

    const onLiveAnnotationChange = (annotation: boolean) => {

        if (annotation) {
            closeModal();
        }
    }


    return (
        <>
            <UnseenItemButton
                total={downloadURLs.length}
                typeName="image"
                label="View and download images"
                unseen={unseenImages}
                showText={showText}
                icon={<DownloadIcon />}
                onClick={openModal} />
            <TelehealthDialogue
                open={modelOpen}
                title={title}
                onClose={closeModal}
                maxWidth="lg">
                <TelehealthImage currentImage={currentImage} />
                <DownloadBar
                    tooltip={"Download Image"}
                    currentOptionIndex={currentImageIndex}
                    optionsLength={downloadURLs?.length || 0}
                    onNavigate={selectCurrentIndex}
                    onAccept={
                        currentExpired ?
                            undefined : acceptDownload} >
                    {liveAnnotation &&
                        <ActivateLiveAnnotation
                            url={currentImage}
                            showText={showText}
                            liveAnnotationChanged={onLiveAnnotationChange} />}
                </DownloadBar>
            </TelehealthDialogue >
            {currentImage && <a href={currentImage} ref={linkRef} download="image.jpg" />}
        </>
    );
}

export default DownloadImageModal;