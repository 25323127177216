import { Stack, Typography } from "@mui/material";


interface InstructionsLabelProps {
    distance: number;
    angle: number;
    area: number;
    perimeter: number;
    showAreaTool: boolean;
    errorMessage: string | null;
}

const InstructionsLabel: React.FC<InstructionsLabelProps> = ({
    distance,
    angle,
    area,
    perimeter,
    showAreaTool,
    errorMessage,
}) => {

    const distanceLabel = distance < 0 ? "Click to measure points" : `Distance: ${distance.toFixed(2)} cm, Angle: ${angle.toFixed(2)} degrees`;
    const controlLabel = "Left click and drag to rotate, right click and drag to pan, scroll to zoom";
    const areaLabel = area <= 0 ? "Click to measure area" : `Area: ${area.toFixed(2)} cm² Perimeter: ${perimeter.toFixed(2)} cm`;

    const label = showAreaTool ? areaLabel : distanceLabel;

    return (
        <Stack direction={"row"} spacing={1}>
            <Stack direction={"column"} spacing={1} alignItems={"center"}>
                <Typography variant="h6" color="primary">
                    {controlLabel}
                </Typography>
                <Typography variant="h6" color="primary">
                    {label}
                </Typography>
            </Stack>
            {errorMessage &&
                <Typography variant="h6" color="error">
                    {errorMessage}
                </Typography>}
        </Stack>
    )
}

export default InstructionsLabel;