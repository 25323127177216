import React from "react";

import DrawIcon from '@mui/icons-material/Draw';
import HideImageIcon from '@mui/icons-material/HideImage';

import { MeetingInfoContext } from "../../context/meeting-info-context";
import { ConnectIoTContext } from "../../websocket/connect-iot";
import { MessageType } from "../../annotation/send-annotation-message";

import ControlButton from "../control-button";

interface ActivateLiveAnnotationProps {
    url?: string;
    showText?: boolean;
    liveAnnotationChanged?: (showAnnotation: boolean) => void;
}

const ActivateLiveAnnotation: React.FC<ActivateLiveAnnotationProps> = ({
    url,
    showText,
    liveAnnotationChanged,
}) => {

    const { connected, meetingId } = React.useContext(MeetingInfoContext);

    const channelName = `liveMeeting/${meetingId}/annotation`;

    const { sendMessage } = React.useContext(ConnectIoTContext);

    const { setAnnotationUrl, showAnnotation, setShowAnnotation } = React.useContext(MeetingInfoContext);

    const onClick = () => {

        if (showAnnotation) {
            updateUrl("", false);
            return;
        }

        if (!url) {
            return;
        }

        updateUrl(url, true);
    }

    const updateUrl = (url: string, active: boolean) => {
        setShowAnnotation(active);
        setAnnotationUrl(url);

        //replace / with %2F
        const safeUrl = url.replace(/\//g, "%2F");

        const message = {
            type: MessageType.Active,
            active: active,
            url: safeUrl,
        }

        console.log("~~Sending message", message);

        sendMessage(channelName, JSON.stringify(message));

        if (liveAnnotationChanged) {
            liveAnnotationChanged(active);
        }
    }

    const disabled = !connected || (!url && !showAnnotation);

    return (
        <ControlButton
            showText={showText}
            disabled={disabled}
            onClick={onClick}
            active={showAnnotation}
            activeColor={"#c62828"}
            activeIcon={<HideImageIcon />}
            inactiveIcon={<DrawIcon />}
            activeLabel={"Stop Live Annotation"}
            inactiveLabel={"Start Live Annotation"}
        />
    )
}

export default ActivateLiveAnnotation;