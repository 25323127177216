import { Card, Divider, SelectChangeEvent, Stack, useTheme } from "@mui/material";

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CancelIcon from '@mui/icons-material/Cancel';

import ControlButton from "../components/control-button";

import OrganisationColorSelect from "../components/organisation/organisation-color-select";
import ActivateLiveAnnotation from "../components/upload/activate-live-annotation";


interface AnnotationControlBarProps {
    color: string;
    canReset: boolean;
    handleColorChange: (color: string) => void;
    onReset: () => void;
    onUndo: () => void;
}

const AnnotationControlBar: React.FC<AnnotationControlBarProps> = ({
    color,
    canReset,
    handleColorChange,
    onReset,
    onUndo,
}) => {

    const theme = useTheme();

    const onColorChange = (event: SelectChangeEvent<string>) => {
        handleColorChange(event.target.value);
    }

    return (
        <Stack
            direction={"column"}
            spacing={1}
            width={"fit-content"}
            alignItems={"center"}
            alignContent={"center"}>
            <ActivateLiveAnnotation />
            <Divider />
            <ControlButton
                onClick={onUndo}
                showText={false}
                disabled={!canReset}
                active={canReset}
                activeColor={theme.palette.primary.light}
                activeLabel={"Undo Last Point"}
                inactiveLabel={"Undo Last Point"}
                inactiveIcon={<RestartAltIcon />}
                activeIcon={<RestartAltIcon />}
                tooltipPlacement="right" />
            <ControlButton
                onClick={onReset}
                showText={false}
                disabled={!canReset}
                active={canReset}
                activeColor={theme.palette.primary.light}
                activeLabel={"Reset Points"}
                inactiveLabel={"Reset Points"}
                inactiveIcon={<CancelIcon />}
                activeIcon={<CancelIcon />}
                tooltipPlacement="right" />
            <Divider />
            <Card sx={{
                backgroundColor: "lightgray",
            }}>
                <OrganisationColorSelect
                    compact={true}
                    selectedColor={color}
                    onSelectColor={onColorChange} />
            </Card>
        </Stack>
    );
}

export default AnnotationControlBar;