import React from "react"

import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material"

interface DictionarySelectProps {
    name: string
    label: string
    multiple?: boolean
    compact?: boolean
    dictionary: { [key: string]: string }
    selected: any
    onSelect: (event: any) => void
    createSelectIcon?: (key: string, value: string) => JSX.Element
}

const DictionarySelect: React.FC<DictionarySelectProps> = ({
    name,
    label,
    multiple,
    compact = false,
    dictionary,
    selected,
    onSelect,
    createSelectIcon,
}) => {

    return (
        <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"left"}
            alignItems={"center"}
            width={"100%"}>
            <FormControl
                fullWidth={!compact}
                aria-labelledby={compact ? undefined : `${name}-select-label`}>
                {!compact && <InputLabel
                    id={`${name}-select-label`}
                    color="primary">
                    {label}
                </InputLabel>}
                <Select
                    fullWidth={!compact}
                    multiple={multiple}
                    name={name}
                    label={compact ? undefined : name}
                    id={`${name}-select`}
                    aria-labelledby={`${name}-select-label`}
                    labelId={`${name}-select-label`}
                    value={selected}
                    onChange={onSelect}
                    sx={{
                        minWidth: compact ? undefined : "160px",
                        height: "50px",
                    }}>
                    {Object.entries(dictionary).map(([key, value]) => {
                        return (
                            <MenuItem key={key} value={key} >
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    paddingX={compact ? 0 : 1}
                                    spacing={compact ? 0 : 2}>
                                    {createSelectIcon && createSelectIcon(key, value)}
                                    {!compact && <Typography>
                                        {value}
                                    </Typography>}
                                </Stack >
                            </MenuItem >
                        )
                    })}
                </Select >
            </FormControl>
        </Stack >
    )
}

export default DictionarySelect;