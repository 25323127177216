import React, { PropsWithChildren, useEffect } from "react";

import { Stack } from "@mui/material";

import { ConnectIoTContext } from "../websocket/connect-iot";
import { MeetingInfoContext } from "../context/meeting-info-context";
import AnnotationGridMessage from "./annotation-grid";

import AnnotationControlBar from "./annotation-control-bar";

export enum MessageType {
    Annotation = "annotation",
    Clear = "clear",
    Undo = "undo",
    RequestPoints = "request-points",
    SendPoints = "send-points",
    Color = "color",
    Active = "active",
}

export interface Point {
    x: number;
    y: number;
}

export interface Drawing {
    points: Point[];
    color: string;
}

interface SendAnnotationMessageProps {
    userId: string;
}

const SendAnnotationMessage: React.FC<PropsWithChildren<SendAnnotationMessageProps>> = ({
    userId,
    children,
}) => {

    const { subscribeToChannel, unsubscribeFromChannel, sendMessage } = React.useContext(ConnectIoTContext);

    const { connected, meetingId } = React.useContext(MeetingInfoContext);

    const [color, setColor] = React.useState<string>("#e19b9b");

    const [listenerSet, setListenerSet] = React.useState(false);

    const [pState, setPState] = React.useState<{ [id: string]: Drawing }>({});

    const [requestPoints, setRequestPoints] = React.useState<string[]>([]);

    const channelName = `liveMeeting/${meetingId}/annotation`;

    const { showAnnotation, setShowAnnotation, annotationUrl, setAnnotationUrl } = React.useContext(MeetingInfoContext);

    let canReset = false;

    if (pState[userId]) {
        canReset = pState[userId].points.length > 0;
    }

    const respondToMessage = (topicName: string, payload: string) => {

        console.log("~~Received!!! message", topicName, payload);

        if (topicName !== channelName) {
            console.warn("~~Received message from unexpected channel", topicName);
            return;
        }

        const { type, sender, target } = JSON.parse(payload);

        switch (type) {
            case MessageType.Annotation: {
                receiveAnnotationMessage(payload, sender);
                break
            }
            case MessageType.Clear: {
                receiveClearMessage(sender);
                break
            }
            case MessageType.Undo: {
                receiveUndoMessage(sender);
                break
            }
            case MessageType.RequestPoints: {
                receiveRequestPointsMessage(sender);
                break
            }
            case MessageType.SendPoints: {
                receiveSendPointsMessage(target, payload);
                break
            }
            case MessageType.Color: {
                receiveColorMessage(sender, payload);
                break;
            }
            case MessageType.Active: {
                receiveActiveMessage(payload);
                break;
            }
            default: {
                console.warn("~~Received unexpected message type", type);
            }
        }
    }

    const receiveActiveMessage = (payload: string) => {

        const { active, url } = JSON.parse(payload);

        const isActive = active === true || active === "true";

        setActiveState(isActive, url);
    }

    const setActiveState = (isActive: boolean, url: string) => {
        if (isActive) {
            if (url === annotationUrl) {
                return;
            }

            if (!url) {
                console.warn("~~Received active message with no url");
                return;
            }
        }

        if (isActive) {

            //restore illegal / characters
            const safeUrl = url.replace(/%2F/g, "/");

            console.log("~~ ^^ Received active message", isActive, safeUrl);

            setAnnotationUrl(safeUrl);
            setShowAnnotation(isActive);
        }
        else {
            setAnnotationUrl(null);
            setShowAnnotation(isActive);
        }
    }

    const receiveColorMessage = (sender: string, payload: string) => {

        const { color } = JSON.parse(payload);

        setPState(prevPState => {
            const updated = { ...prevPState };

            if (!updated[sender]) {
                updated[sender] = { points: [], color };
            } else {
                updated[sender].color = color;
            }

            return updated;
        });
    }

    const receiveAnnotationMessage = (payload: string, sender: string) => {

        const { x, y, color } = JSON.parse(payload);

        setPState(prevPState => {
            const updated = { ...prevPState };

            if (!updated[sender]) {
                updated[sender] = { points: [{ x, y }], color };
            } else {
                updated[sender].points.push({ x, y });
                updated[sender].color = color;
            }

            return updated;
        });
    }

    const receiveClearMessage = (sender: string) => {
        setPState(prevState => {
            const newState = { ...prevState };
            delete newState[sender];
            return newState;
        });
    }

    const receiveUndoMessage = (sender: string) => {
        setPState(prevState => {
            const newState = { ...prevState };
            newState[sender].points.pop();
            return newState;
        });
    }

    const receiveRequestPointsMessage = (sender: string) => {

        if (sender === userId) {
            console.log("~~Received request points message from self");
            return;
        }

        setRequestPoints(prevRequestPoints => {
            if (prevRequestPoints.includes(sender)) {
                return prevRequestPoints;
            } else {
                return [...prevRequestPoints, sender];
            }
        });

        console.log("~~Received request points message from", sender);
    }

    const receiveSendPointsMessage = (target: string, payload: string) => {

        if (target != userId) {
            console.log("~~Received send points message for another user", target);
            return;
        }

        const { points } = JSON.parse(payload);

        console.log("~~Received points", points);

        setPState(prevState => {
            return points;
        });

        console.log("~~Received send points message to", target);
    }

    useEffect(() => {
        if (!connected) {
            return;
        }

        if (listenerSet) {
            return;
        }

        console.log("~~Subscribing to annotation channel", channelName);

        subscribeToChannel(channelName, respondToMessage, onFinished);

        setListenerSet(true);

        // Clean up
        return () => {
            console.log("Unsubscribing from annotation channel");
            // unsubscribeFromChannel(channelName);
            // setListenerSet(false);
        }

    }, [connected]);

    useEffect(() => {
        if (!connected) {
            return;
        }

        if (requestPoints.length === 0) {
            return;
        }

        requestPoints.forEach(target => {

            const message = {
                sender: userId,
                type: MessageType.SendPoints,
                points: pState,
                target: target,
            }

            sendMessage(channelName, JSON.stringify(message));
        });

        console.log("~~Send points message sent to", requestPoints);

        setRequestPoints([]);
    }, [requestPoints]);

    const onFinished = (error?: string) => {

        if (error) {
            console.error("~~Error subscribing to annotation channel", error);
            return;
        }

        const message = {
            sender: userId,
            type: MessageType.RequestPoints,
        }

        sendMessage(channelName, JSON.stringify(message));
    }

    const sendMessageOnClick = (x: number, y: number) => {

        const message = {
            sender: userId,
            type: MessageType.Annotation,
            x,
            y,
            color,
        }

        sendMessage(channelName, JSON.stringify(message));
        console.log("~~Annotation message sent", message);
    }

    const handleColorChange = (color: string) => {
        setColor(color);

        const message = {
            sender: userId,
            type: MessageType.Color,
            color,
        }

        sendMessage(channelName, JSON.stringify(message));
    }

    const handleClearOnClick = () => {

        const message = {
            sender: userId,
            type: MessageType.Clear,
        }

        sendMessage(channelName, JSON.stringify(message));
        console.log("~~Clear message sent", message);
    }

    const handleUndoOnClick = () => {

        const message = {
            sender: userId,
            type: MessageType.Undo,
        }

        sendMessage(channelName, JSON.stringify(message));
        console.log("~~Undo message sent");
    }

    return (
        <Stack spacing={1}>
            <AnnotationGridMessage
                points={pState}
                onClick={sendMessageOnClick}>
                {children}
            </AnnotationGridMessage>
            {showAnnotation &&
                <div style={{
                    position: 'fixed',
                    left: "10px",
                    top: "40%",
                    zIndex: 1000,
                }}>
                    <AnnotationControlBar
                        color={color}
                        handleColorChange={handleColorChange}
                        onReset={handleClearOnClick}
                        onUndo={handleUndoOnClick}
                        canReset={canReset}
                    />
                </div>}
        </Stack>
    )
}

export default SendAnnotationMessage;