import React, { useEffect } from "react";

import {
    MeetingStatus,
    useMeetingManager,
} from "amazon-chime-sdk-component-library-react";

import { MeetingInfoContext } from "../../context/meeting-info-context";

const MeetingStatusDisplay: React.FC = () => {

    const meetingManager = useMeetingManager();

    const { errorMessage, setErrorMessage, connected, setConnected } = React.useContext(MeetingInfoContext);

    const [listenerSet, setListenerSet] = React.useState(false);

    useEffect(() => {

        const checkMeetingStatus = (status: MeetingStatus) => {

            if (status === MeetingStatus.Succeeded && connected) {
                return;
            }

            if (status === MeetingStatus.Succeeded && !connected) {
                setConnected(true);
            }
            else if (status != MeetingStatus.Succeeded && connected) {
                setConnected(false);
            }

            if (errorMessage) {
                return;
            }

            if (status === MeetingStatus.Succeeded) {
                setErrorMessage(null);
                return;
            }

            if (status === MeetingStatus.Failed) {
                setErrorMessage("Failed to connect.");
                return;
            }

            if (status === MeetingStatus.Loading) {
                setErrorMessage("Connecting...");
                return;
            }

            if (status === MeetingStatus.Ended) {
                setErrorMessage("Meeting has ended.");
                return;
            }

            if (status === MeetingStatus.JoinedFromAnotherDevice) {
                setErrorMessage("Joined From Another Device.");
                return;
            }

            if (status === MeetingStatus.Reconnecting) {
                setErrorMessage("Reconnecting...");
                return;
            }

            if (status === MeetingStatus.TerminalFailure) {
                setErrorMessage("Terminal Failure.");
                return;
            }
        }

        if (!meetingManager) {
            return;
        }

        if (listenerSet) {
            return;
        }

        meetingManager.subscribeToMeetingStatus(checkMeetingStatus);

        setListenerSet(true);

    }, [meetingManager]);

    return (
        <> </>
    );
};

export default MeetingStatusDisplay;
