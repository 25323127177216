import React, { useEffect, useRef, useState } from 'react';

import { iot, mqtt, mqtt5 } from 'aws-iot-device-sdk-v2';

import { useGetCurrentUser } from '../store';
import { useQueryClient } from '@tanstack/react-query';
import { handleIotMessage } from './iot-event-handler';
import { PublishPacket, QoS, SubscribePacket } from 'aws-crt/dist/common/mqtt5_packet';
import { toUtf8 } from '@aws-sdk/util-utf8-browser';
import { ResourceType } from '../types';
import { telehealthStaticCredentialProvider } from './telehealth-static-credential-provider';
import { AuthContext } from '../context/auth-context';

const region = import.meta.env.VITE_AWS_REGION as string;
const websocketEndPoint = import.meta.env.VITE_WEBSOCKET_API_ENDPOINT as string

const channel = 'update/';

const subPacket: mqtt5.SubscribePacket =
{
  subscriptions: [
    {
      topicFilter: channel + ResourceType.Meetings,
      qos: mqtt.QoS.AtMostOnce,
    },
    {
      topicFilter: channel + ResourceType.Uploads,
      qos: mqtt.QoS.AtMostOnce,
    },
    {
      topicFilter: channel + ResourceType.Patients,
      qos: mqtt.QoS.AtMostOnce,
    },
    {
      topicFilter: channel + ResourceType.Organisation,
      qos: mqtt.QoS.AtMostOnce,
    },
    {
      topicFilter: channel + ResourceType.Users,
      qos: mqtt.QoS.AtMostOnce,
    }
  ]
}

const unSubPacket: mqtt5.UnsubscribePacket =
{
  topicFilters: [channel + ResourceType.Meetings, channel + ResourceType.Uploads, channel + ResourceType.Patients, channel + ResourceType.Organisation, channel + ResourceType.Users]
}

export type ConnectIoTContextType = {
  subscribeToChannel: (topicName: string, handleMessage: (topicName: string, payload: string) => void, onFinish?: (error?: string) => void) => void;
  unsubscribeFromChannel: (topicName: string) => void;
  sendMessage: (topicName: string, payload: string) => void;
};

export const ConnectIoTContext = React.createContext<ConnectIoTContextType>({
  subscribeToChannel: () => { },
  unsubscribeFromChannel: () => { },
  sendMessage: () => { },
})

interface SubscriptionEntry {
  packet: SubscribePacket;
  handleMessage: (topicName: string, payload: string) => void;
}

const ConnectIoT: React.FC<React.PropsWithChildren> = ({ children }) => {

  const [clientConnection, setClientConnection] = useState<mqtt5.Mqtt5Client | null>(null);
  const [lastUserId, setLastUserId] = useState<string | null>(null);
  const [lastOrganisationId, setLastOrganisationId] = useState<string | undefined>(undefined);

  const [subDict, setSubDict] = useState<{ [key: string]: SubscriptionEntry | undefined }>({});

  const { data: currentUser } = useGetCurrentUser();
  const queryClient = useQueryClient();

  const { assumeIdentity } = React.useContext(AuthContext);

  const waitToConnect = async (accessKeyId: string, secretKey: string, sessionToken: string, organisationId: string, listener: mqtt5.MessageReceivedEventListener) => {

    if (!accessKeyId || !secretKey || !sessionToken) {
      console.error('No access token or id token');
      return null;
    }

    const credentials = new telehealthStaticCredentialProvider(accessKeyId, secretKey, sessionToken, region);

    const config = iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithSigv4Auth(
      websocketEndPoint,
      {
        region: region,
        credentialsProvider: credentials,
      }).build();

    const client = new mqtt5.Mqtt5Client(config);

    client.on('connectionSuccess', () => {
      console.log('Connected to AWS IoT');
    });

    client.on("messageReceived", listener);

    client.start();

    const organisationChimeTopic = channel + ResourceType.Chime + "/" + organisationId;

    const userSubPacket = {
      subscriptions: [
        ...subPacket.subscriptions,
        {
          topicFilter: organisationChimeTopic,
          qos: mqtt.QoS.AtMostOnce,
        }
      ]
    }

    client.subscribe(userSubPacket);

    return client;
  }

  const onEvent: mqtt5.MessageReceivedEventListener = (eventData: mqtt5.MessageReceivedEvent) => {

    if (!eventData.message.payload) {
      console.warn('No payload', eventData);
      return;
    }

    const payload = toUtf8(eventData.message.payload as Buffer);

    handleMessage(eventData.message.topicName, payload);
  }

  const handleMessage = (topicName: string, payload: string) => {

    if (!currentUser) {
      console.log('~~No current user to handle IoT message');
      return;
    }

    if (subDict[topicName]) {
      subDict[topicName]?.handleMessage(topicName, payload);
      console.log('~~Handled message from', topicName);
      return;
    }

    handleIotMessage(topicName, payload, currentUser, queryClient);
  }

  useEffect(() => {

    const waitToCreateConnection = async () => {
      const identity = await assumeIdentity(false)

      if (!identity || !currentUser) {
        console.warn('No identity or current user');
        return;
      }

      if (!identity.accessKeyId || !identity.secretKey || !identity.sessionToken) {
        console.warn('No access token or id token');
        return;
      }

      const connection = await waitToConnect(identity?.accessKeyId, identity?.secretKey, identity?.sessionToken, currentUser?.organisationId, onEvent);

      if (connection != clientConnection) {
        closeConnection(clientConnection, lastOrganisationId, 'Disconnecting from AWS IoT due to connection change');
        setClientConnection(connection || null);
      }

      if (lastUserId !== currentUser?.userId) {
        setLastUserId(currentUser?.userId || null);
        setLastOrganisationId(currentUser?.organisationId);
      }
    }

    if (currentUser && !clientConnection) {
      waitToCreateConnection();
    }
  }, [currentUser]);

  const subscribeToChannel = async (topicName: string, handleMessage: (topicName: string, payload: string) => void, onFinish?: (error?: string) => void) => {
    if (!clientConnection) {
      console.warn('No connection to subscribe to');
      return;
    }

    const packet: SubscribePacket = {
      subscriptions: [
        {
          topicFilter: topicName,
          qos: mqtt5.QoS.AtMostOnce,
        }
      ]
    }

    const subscriptionEntry: SubscriptionEntry = {
      packet,
      handleMessage
    }

    setSubDict(prev => {
      const updated = prev;
      updated[topicName] = subscriptionEntry;
      return updated
    })

    const response = await clientConnection.subscribe(packet);

    console.log('~~Subscribed to', topicName);

    if (!onFinish) {
      return;
    }

    if (response.reasonCodes[0] !== mqtt5.SubackReasonCode.GrantedQoS0) {
      console.warn('~~Subscription failed', response.reasonString);
      onFinish(response.reasonString);
      return;
    }

    onFinish();
  }

  const sendMessage = (topicName: string, payload: string) => {
    if (!clientConnection) {
      console.warn('No connection to send message');
      return;
    }

    const packet: PublishPacket = {
      topicName,
      qos: QoS.AtMostOnce,
      payload,
    }

    clientConnection.publish(packet);
  }

  const unsubscribeFromChannel = (topicName: string) => {
    if (!clientConnection) {
      console.warn('~~No connection to unsubscribe from');
      return;
    }

    const packet: mqtt5.UnsubscribePacket = {
      topicFilters: [topicName]
    }

    clientConnection.unsubscribe(packet);

    setSubDict(prev => {
      const updated = prev;
      prev[topicName] = undefined;

      return updated;
    })

    console.log('~~Unsubscribed from', topicName);
  }

  const closeConnection = (clientConnection: mqtt5.Mqtt5Client | null, organisationId?: string, message?: string) => {
    if (!clientConnection) {
      return;
    }

    if (organisationId) {
      const organisationChimeTopic = channel + ResourceType.Chime + "/" + organisationId;

      const userUnSubPacket = {
        topicFilters: [
          ...unSubPacket.topicFilters,
          organisationChimeTopic
        ]
      }

      clientConnection.unsubscribe(userUnSubPacket);
    }
    else {
      clientConnection.unsubscribe(unSubPacket);
    }

    clientConnection.close();

    if (message) {
      console.log(message);
    }
  }

  return (
    <ConnectIoTContext.Provider value={{
      subscribeToChannel,
      unsubscribeFromChannel,
      sendMessage,
    }}>
      {children}
    </ConnectIoTContext.Provider>
  );
};

export default ConnectIoT