import { Dispatch, SetStateAction } from "react"

import { v4 as uuidv4 } from "uuid";

import {
  Dialog,
  Stack,
  Typography,
} from "@mui/material"

import { Meeting, MeetingStatus } from "../../types"

import CancelButton from "../cancel-button"
import MeetingForm from "./meeting-form"

interface AddMeetingModalProps {
  open: boolean
  useStartDateInForm: boolean
  userId: string
  startTime?: Date
  patientId?: string
  organisationIds?: string[]
  handleClose: Dispatch<SetStateAction<void>>
  addMeeting: (meeting: Meeting) => void
}

const AddMeetingModal: React.FC<AddMeetingModalProps> = ({
  open,
  useStartDateInForm,
  startTime = new Date(),
  patientId = "",
  userId,
  organisationIds = [],
  handleClose,
  addMeeting,
}: AddMeetingModalProps) => {

  const meetingName = startTime.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })

  const meetingToAdd: Meeting = {
    meetingId: uuidv4(),
    creatorId: userId,
    meetingName: meetingName,
    startTime: startTime,
    endTime: new Date(startTime.getTime() + 30 * 60000),
    chimeJoinId: "",
    attendeesId: [],
    confirmedAttendeesId: [],
    joinedIds: [],
    meetingType: MeetingStatus.Scheduled,
    patientId: patientId,
    organisationIds: organisationIds,
  }

  const onClose = () => {
    handleClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Stack
        spacing={2}
        padding={2}>
        <Typography variant="h2">
          Schedule Meeting
        </Typography>
        <CancelButton handleClose={onClose} />
        <Typography variant="body1">
          To schedule a meeting, please fill in the information below.
        </Typography>
        <MeetingForm
          useStartDateInForm={useStartDateInForm}
          handleClose={onClose}
          meeting={meetingToAdd}
          addMeeting={addMeeting} />
      </Stack>
    </Dialog >
  )
}

export default AddMeetingModal
