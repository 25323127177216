import { Card, Container, Stack, useTheme } from "@mui/material"
import NavigationTelehealthToolbar from "../components/navigation-telehealth-toolbar"
import { useGetCurrentUser } from "../store"
import UploadFileButton from "../components/upload/upload-file-button"
import React, { useEffect, useState } from "react"
import ModelPanel from "../components/three/model-panel"

const ModelPage: React.FC = () => {

    const [model, setModel] = useState<File | null>(null)
    const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
    const [patientId, setPatientId] = useState<string>("???");

    const { data: currentUser } = useGetCurrentUser()

    const theme = useTheme();

    useEffect(() => {
        if (!model) {
            return;
        }

        const fileNames = model.name.split(".");

        if (fileNames.length > 1) {
            const words = fileNames[0].split(" ");

            if (words.length > 1) {
                setPatientId(words[1]);
            }
            else {
                setPatientId("???");
            }
        }

        const reader = new FileReader();
        reader.onload = (e) => {

            const url = e.target?.result as string;

            if (!url) {
                console.error("Failed to load model");
                return;
            }

            setFileUrl(url);
        };
        reader.readAsDataURL(model);
    }, [model]);

    const onFileUpload = (file: File | null) => {
        setModel(file)
    }

    const onCancel = () => {
        console.log("Model Upload Cancelled")
    }

    return (
        <Stack
            justifyContent="top"
            alignItems="center"
            spacing={2}
            sx={{
                minHeight: "100vh",
                backgroundColor: "#F5F5F5",
            }}>
            <NavigationTelehealthToolbar currentUser={currentUser} />
            <Stack
                spacing={2}
                padding={2}
                sx={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                }}>
                <ModelPanel
                    patientId={patientId}
                    modelUrl={fileUrl}
                    height="80vh"
                    width="85vw"
                />
                <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent={"center"}
                    width={"100%"}>
                    <UploadFileButton
                        capture={false}
                        active={true}
                        disabled={false}
                        showText={true}
                        activeColor={theme.palette.primary.light}
                        activeLabel="Upload Model"
                        inactiveLabel="Upload Model"
                        onClick={() => { }}
                        onFile={onFileUpload}
                        reportCancel={onCancel}
                        accept={".ply"} />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ModelPage;