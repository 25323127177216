import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

import UploadFileButton from '../upload/upload-file-button';
import { Vector3 } from "three";

import { useTheme } from '@mui/material';
import ControlButton from '../control-button';
import { ThreeContext } from '../../context/three-context';
import { useContext } from 'react';

interface DownloadPointsProps {
    points: Vector3[];
    area: number;
    disabled: boolean;
    patientId: string;
    showText?: boolean;
    onPointsUploaded: (points: Vector3[]) => void;
}


const DownloadPoints: React.FC<DownloadPointsProps> = ({
    points,
    area,
    disabled,
    patientId,
    showText,
    onPointsUploaded,
}) => {

    const theme = useTheme();

    const { isLoading } = useContext(ThreeContext);

    const uploadDisabled = points.length > 0 || disabled || isLoading;
    const downloadDisabled = points.length < 3 || disabled || isLoading;

    const activeColor = theme.palette.primary.light;

    const extension = ".json";

    const timeString = new Date().toLocaleDateString().replace(/\//g, '-');

    const fileName = `Patient ${patientId} Points ${timeString}${extension}`;

    const startDownloadPoints = () => {

        const data = {
            points,
            area,
        }

        const pointsString = JSON.stringify(data);
        const blob = new Blob([pointsString], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const onFileUploaded = (file: File | null) => {
        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target?.result as string;

            const { points } = JSON.parse(text);

            if (!points) {
                console.error("Failed to parse points");
                return;
            }

            const vectorPoints = points.map((point: any) => {
                return new Vector3(point.x, point.y, point.z);
            });

            onPointsUploaded(vectorPoints);
        };
        reader.readAsText(file);
    }

    return (
        <>
            <UploadFileButton
                capture={false}
                active={true}
                disabled={uploadDisabled}
                activeColor={activeColor}
                activeLabel={"Upload Points"}
                inactiveLabel={"Upload Points"}
                activeTooltip={"Upload Points"}
                inactiveTooltip={"Clear points to upload"}
                onClick={() => { }}
                onFile={onFileUploaded}
                showText={showText}
                accept={extension}
                tooltipPlacement='right' />
            <ControlButton
                activeIcon={<DownloadForOfflineIcon color={"action"} />}
                inactiveIcon={<DownloadForOfflineIcon color={"action"} />}
                activeLabel="Download Points"
                inactiveLabel={"Download Points"}
                activeTooltip={"Download Points"}
                inactiveTooltip={"Not enough points to download"}
                active={true}
                disabled={downloadDisabled}
                showText={showText}
                onClick={startDownloadPoints}
                activeColor={activeColor}
                tooltipPlacement='right' />
        </>
    )
}

export default DownloadPoints;