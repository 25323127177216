import React from "react";

import { FormControl, MenuItem, Select, SelectChangeEvent, Stack, Typography, useTheme } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface VisibilitySelectProps {
    id: string;
    selectType: string
    selected: string[];
    dictionary: VisibilityDictionary;
    ids: string[];
    handleChange: (selected: string[]) => void;
}

export interface VisibilityDictionary {
    [key: string]: {
        name: string;
        type: string;
        title: string;
    }
}

const VisibilitySelect: React.FC<VisibilitySelectProps> = ({
    id,
    selectType,
    selected,
    dictionary,
    ids,
    handleChange,
}) => {

    const theme = useTheme();

    const selectedAttendeeNames: string[] = selected.map((id) => {

        if (!dictionary[id]) {
            return "";
        }

        if (dictionary[id].type.length === 0) {
            return dictionary[id].name;
        }

        return `${dictionary[id].name} (${dictionary[id].type})`;
    });

    const selectChangeEvent = (event: SelectChangeEvent<string[]>) => {
        if (!event.target.value) {
            return;
        }

        const newSelectedAttendees = event.target.value as string[];

        handleChange(newSelectedAttendees);
    }

    return (
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="body1" color={"black"}>Selected Attendees:</Typography>
            <FormControl >
                <Select
                    id={id}
                    name={id}
                    value={selected}
                    multiple={true}
                    sx={{ minWidth: "200px" }}
                    onChange={selectChangeEvent}
                    renderValue={(selected) => {
                        if (selectedAttendeeNames.length === 0) {
                            return <em>{`No ${selectType} Selected`}</em>;
                        }

                        return selectedAttendeeNames.join(', ');
                    }}>
                    {ids.map((id) => {

                        if (!dictionary[id]) {
                            return null;
                        }

                        if (dictionary[id].name.length === 0) {
                            return null;
                        }

                        let name = dictionary[id].name;

                        if (dictionary[id].type.length > 0) {
                            name += ` (${dictionary[id].type})`;
                        }

                        if (dictionary[id].title.length > 0) {
                            name = `(${dictionary[id].title}) ${name}`;
                        }

                        return <MenuItem
                            value={id}
                            key={id}
                            sx={{
                                color: selected.includes(id) ? `${theme.palette.primary.main}` : `${theme.palette.grey}`,
                            }}>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}>
                                {selected.includes(id) ?
                                    <VisibilityIcon /> : <VisibilityOffIcon />}
                                <Typography variant="body1">
                                    {name}
                                </Typography>
                            </Stack>
                        </MenuItem>;
                    })}
                </Select>
            </FormControl >
        </Stack>
    );
}

export default VisibilitySelect;