import React, { PropsWithChildren, Suspense, useRef } from "react";

import { Card, CircularProgress, Divider, Stack } from "@mui/material"

import { Vector3 } from "three";

import { urlExpired } from "../upload/url-expired";

import { Canvas } from "@react-three/fiber";
import { Html, OrbitControls } from "@react-three/drei";

import ModelControlPanel from "./model-control-panel";
import ModelFromUrl from "./model-from-url";
import PointLineGroup from "./point-line-group";
import PlaneFromPoints from "./plane-from-points";
import { shoeStringArea } from "./area-formulas";
import DownloadPoints from "./download-points";
import InstructionsLabel from "./instructions-label";
import { ThreeContext } from "../../context/three-context";


interface ModelPanelProps {
    patientId: string;
    modelUrl?: string;
    height?: string;
    width?: string;
}

const ModelPanel: React.FC<PropsWithChildren<ModelPanelProps>> = ({
    modelUrl,
    patientId,
    height = "80vh",
    width = "60vw",
    children,
}) => {

    const [modelPosition, setModelPosition] = React.useState(new Vector3(0, 0, 0));
    const [target, setTarget] = React.useState(new Vector3(0, 0, 0));

    const [distance, setDistance] = React.useState<number>(-1);
    const [perimeter, setPerimeter] = React.useState<number>(-1);
    const [angle, setAngle] = React.useState<number>(-1);
    const [area, setArea] = React.useState<number>(-1);
    const [points, setPoints] = React.useState<Vector3[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [color, setColor] = React.useState<string>("#e4f7ff");

    const [showAreaTool, setShowAreaTool] = React.useState<boolean>(true);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const orbitControlsRef = useRef<any>(null);

    const pointsChanged = (event: any) => {
        const point = event.point;

        const newPoints =
            (points.length === 2 && !showAreaTool) ?
                [point] :
                [...points, point];

        if (newPoints.length < 2) {
            setPoints(newPoints);
            setDistance(-1);
            return;
        }

        const { area, selfIntersecting, perimeter } = shoeStringArea(newPoints);

        if (selfIntersecting) {
            setErrorMessage("Cannot add self intersecting point");
            return;
        }

        updateAngle(newPoints)
        updateDistance(newPoints);

        setArea(area);
        setPerimeter(perimeter)

        setPoints(newPoints);
        setErrorMessage(null);
    }

    const updateDistance = (points: Vector3[]) => {
        if (points.length < 2) {
            setDistance(-1);
            return;
        }

        setDistance(points[0].distanceTo(points[1]))
    }

    const updateAngle = (points: Vector3[]) => {
        if (points.length < 2) {
            setAngle(-1);
            return;
        }

        const angle = points[0].angleTo(points[1]) * 180 / Math.PI;

        setAngle(angle);
    }

    const toggleMeasureTool = () => {

        if (!showAreaTool) {
            return;
        }

        setShowAreaTool(false);

        resetPoints();
    }

    const toggleAreaTool = () => {

        if (showAreaTool) {
            return;
        }

        setShowAreaTool(true);

        resetPoints();
    }

    const resetPoints = () => {
        setPoints([]);
        setDistance(-1);
        setAngle(-1);
        setArea(-1);
        setPerimeter(-1);
    }

    const undoLastPoint = () => {
        if (points.length === 0) {
            return;
        }

        const newPoints = points.slice(0, points.length - 1);
        setPoints(newPoints);

        const { area } = shoeStringArea(newPoints);
        setArea(area);

        updateDistance(newPoints)
        updateAngle(newPoints)
    }

    const expired = urlExpired(modelUrl || "");

    const resetOrbitControls = () => {

        if (orbitControlsRef.current) {
            orbitControlsRef.current.reset();
        }
    };

    const loadPoints = (points: Vector3[]) => {

        if (!showAreaTool) {
            setShowAreaTool(true);
        }

        setPoints(points);
        updateDistance(points);
        updateAngle(points);
        const { area } = shoeStringArea(points);
        setArea(area);
    }

    return (
        <ThreeContext.Provider value={{
            points,
            setPoints,
            distance,
            setDistance,
            angle,
            setAngle,
            area,
            setArea,
            perimeter,
            setPerimeter,
            showAreaTool,
            setShowAreaTool,
            isLoading,
            setIsLoading,
        }}>
            <Stack
                spacing={1}
                height={height}
                width={width}
                alignItems={"center"}
                justifyItems={"center"}>
                <InstructionsLabel
                    distance={distance}
                    angle={angle}
                    area={area}
                    perimeter={perimeter}
                    showAreaTool={showAreaTool}
                    errorMessage={errorMessage} />
                <Card sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    justifyItems: "center",
                    backgroundColor: "#333333",
                }}>
                    <Stack
                        spacing={1}
                        padding={1}>
                        <ModelControlPanel
                            showAreaTool={showAreaTool}
                            onAreaToolToggle={toggleAreaTool}
                            onMeasureToolToggle={toggleMeasureTool}
                            onCenterModel={resetOrbitControls}
                            onReset={resetPoints}
                            canReset={points.length > 0}
                            onUndo={undoLastPoint}
                            color={color}
                            setColor={setColor}>
                            <Divider />
                            <DownloadPoints
                                patientId={patientId}
                                disabled={!modelUrl}
                                points={points}
                                area={area}
                                onPointsUploaded={loadPoints}
                            />
                        </ModelControlPanel>
                    </Stack>
                    <Canvas>
                        {modelUrl &&
                            <Suspense fallback={
                                <Html position={[0, 0, 0]}>
                                    <CircularProgress />
                                </Html>}>
                                <ModelFromUrl
                                    url={modelUrl}
                                    position={modelPosition}
                                    expired={expired}
                                    onClick={pointsChanged} />
                            </Suspense>}
                        <ambientLight intensity={4} />
                        <OrbitControls target={target} ref={orbitControlsRef} />
                        <PointLineGroup points={points} visible={!showAreaTool || showAreaTool} stringLineColor={color} />
                        {/* <PlaneFromPoints points={points} visible={!showAreaTool || showAreaTool} /> */}
                        {/* <mesh position={new Vector3(0, 0, -14)} visible={true}>
                <sphereGeometry args={[1, 36, 36]} />
                <meshBasicMaterial color="green" />
            </mesh> */}
                    </Canvas>
                </Card >
                {children}
            </Stack >
        </ThreeContext.Provider>
    );
}

export default ModelPanel;