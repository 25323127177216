import React, { createContext, PropsWithChildren } from "react";
import { Vector3 } from "three";


interface ThreeContextProps {
    isLoading: boolean,
    setIsLoading: (loading: boolean) => void,
    points: Vector3[],
    setPoints: (points: Vector3[]) => void,
    distance: number,
    setDistance: (distance: number) => void,
    angle: number,
    setAngle: (angle: number) => void,
    area: number,
    setArea: (area: number) => void,
    perimeter: number,
    setPerimeter: (perimeter: number) => void,
    showAreaTool: boolean,
    setShowAreaTool: (show: boolean) => void,
}

export const ThreeContext = createContext<ThreeContextProps>({
    isLoading: false,
    setIsLoading: () => { },
    points: [],
    setPoints: () => { },
    distance: -1,
    setDistance: () => { },
    angle: -1,
    setAngle: () => { },
    area: -1,
    setArea: () => { },
    perimeter: -1,
    setPerimeter: () => { },
    showAreaTool: true,
    setShowAreaTool: () => { },
});

export const ThreeProvider: React.FC<PropsWithChildren<ThreeContextProps>> = ({
    children
}) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [modelUrl, setModelUrl] = React.useState<string>("");
    const [points, setPoints] = React.useState<Vector3[]>([]);
    const [distance, setDistance] = React.useState<number>(-1);
    const [angle, setAngle] = React.useState<number>(-1);
    const [area, setArea] = React.useState<number>(-1);
    const [perimeter, setPerimeter] = React.useState<number>(-1);
    const [showAreaTool, setShowAreaTool] = React.useState<boolean>(true);

    return (
        <ThreeContext.Provider value={{
            isLoading,
            setIsLoading,
            points,
            setPoints,
            distance,
            setDistance,
            angle,
            setAngle,
            area,
            setArea,
            perimeter,
            setPerimeter,
            showAreaTool,
            setShowAreaTool,
        }}>
            {children}
        </ThreeContext.Provider>
    );
};