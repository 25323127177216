import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider, Stack } from '@mui/material';

interface StyledAccordionProps {
    title: string;
}

const StyledAccordion: React.FC<React.PropsWithChildren<StyledAccordionProps>> = ({
    title,
    children,
}) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                    backgroundColor: "#F5F5F5",
                }}
            >
                <Stack>
                    <Typography variant="h4">{title}</Typography>

                </Stack>
            </AccordionSummary>
            <Divider />
            <AccordionDetails sx={{
                backgroundColor: "#F5F5F5",
            }}>
                <Stack spacing={2}>
                    {children}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

export default StyledAccordion;