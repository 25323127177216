import { Drawing, Point } from "./send-annotation-message";


interface DrawingLineProps {
    drawing: Drawing
    userId: string
}

const DrawingLine: React.FC<DrawingLineProps> = ({
    drawing,
    userId,
}) => {

    console.log("~~DrawingLine", drawing, userId);

    const line = drawing?.points || [];

    // Convert points to SVG format
    const points = line.map((p) => `${p.x},${p.y}`).join(' ');

    return (
        <svg width="100%" height="100%" key={`svg${userId}`} style={{
            zIndex: 100,
        }}>
            <polyline points={points} stroke={drawing?.color} fill="none" strokeWidth="2" />
            {line.map((p, index) => (
                <circle key={`${userId}${index}`} cx={p.x} cy={p.y} r="3" fill={drawing?.color} />
            ))}
        </svg>
    );
};

export default DrawingLine;