import { useContext } from "react";

import { Paper, Stack } from "@mui/material";

import LabelledMuteButton from "./labelled-mute-button";
import LabelledAudioButton from "./labelled-audio-button";
import LabelledCameraButton from "./labelled-camera-button";
import LeaveCallButton from "./leave-call-button";
import LabelledContentShareButton from "./labelled-content-share-button";

import { MeetingInfoContext } from "../../context/meeting-info-context";

interface ChimeControlBarProps {
    showText?: boolean;
    startWithVideo?: boolean;
    startWithAudio?: boolean;
    startMuted?: boolean;
    contentShare?: boolean;
    children?: React.ReactNode;
}

const ChimeControlBar: React.FC<ChimeControlBarProps> = ({
    showText,
    startWithVideo = false,
    startWithAudio = true,
    startMuted = false,
    contentShare = false,
    children
}) => {

    const { errorMessage, connected, meetingId, meetingType } = useContext(MeetingInfoContext);
    const disabled = (errorMessage != null && errorMessage.length > 0) || !connected;

    return (
        <Paper
            elevation={20}
            sx={{
                mt: 4,
                padding: 1,
                borderRadius: "10px",
                height: "fit-content",
                width: "fit-content",
                position: "fixed",
                bottom: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 100,
                display: disabled ? "none" : "flex"
            }}
        >
            <Stack
                spacing={1}
                direction={"row"}
                justifyContent="space-around">
                <LabelledMuteButton
                    disabled={disabled}
                    startMuted={startMuted}
                    showText={showText} />
                <LabelledAudioButton
                    disabled={disabled}
                    startWithAudio={startWithAudio}
                    showText={showText} />
                <LabelledCameraButton
                    disabled={disabled}
                    startWithVideo={startWithVideo}
                    showText={showText} />
                {contentShare &&
                    <LabelledContentShareButton
                        disabled={disabled}
                        showText={showText} />}
                {children}
                <LeaveCallButton
                    meetingType={meetingType}
                    showText={showText}
                    meetingId={meetingId} />
            </Stack>
        </Paper>

    );
}

export default ChimeControlBar;