import { SelectChangeEvent } from "@mui/material";
import ColorSelect from "./color-select";


interface OrganisationColorSelectProps {
    selectedColor: string;
    onSelectColor: (event: SelectChangeEvent<string>) => void;
    compact?: boolean;
}

export const colorDictionary: { [id: string]: string; } = {
    ["#e19b9b"]: "red",
    ["#ffd192"]: "orange",
    ["#fdeabd"]: "yellow",
    ["#d8f0d2"]: "green",
    ["#e4f7ff"]: "blue",
    ["#cdb7dd"]: "purple",
    ["#ffe6e6"]: "pink",
    ["#c3b19b"]: "brown"
};

export const findColor = (color: string) => {
    for (const [key,] of Object.entries(colorDictionary)) {
        if (key === color) {
            return key;
        }
    }
    return Object.keys(colorDictionary)[0];
}

const OrganisationColorSelect: React.FC<OrganisationColorSelectProps> = ({
    selectedColor,
    onSelectColor,
    compact = false
}) => {

    return (
        <ColorSelect
            name="color"
            colorDictionary={colorDictionary} selectedColor={selectedColor}
            onSelectColor={onSelectColor}
            compact={compact}
        />
    )
}

export default OrganisationColorSelect;